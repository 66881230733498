<template>
<div>

  <v-card
      class="mx-10 my-4"
  >
    <v-card-text>
      <v-tabs
          color="deep-purple accent-4"
          left
      >
        <v-tab>{{$t('informasi')}}</v-tab>

        <v-tab-item
        >
          <v-container fluid class="body-2">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-flex class="d-flex">
                  <v-icon color="blue">mdi-calendar-range</v-icon>
                  <v-flex class="ml-10">
                    <div>{{$t('tahun')}}</div>
                    <div>{{itemModified.tahun}}</div>
                  </v-flex>
                </v-flex>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="itemModified.lat !=0">
                <v-flex class="d-flex">
                  <v-icon color="blue">mdi-map-marker</v-icon>
                  <v-flex class="ml-10">
                    <div>Latitude, Longitude</div>
                    <div class="caption">{{itemModified.lat}}, {{itemModified.lon}}</div>
                  </v-flex>
                </v-flex>
              </v-col>
            </v-row>

            <v-row class="caption">
              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                     v-if="itemModified.areaTotal!=='' || itemModified.areaPengembangan!=='' || itemModified.areaKosong!=='' || itemModified.skemaPembayaran!==''">
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded> {{$t('informasiUmum')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.areaTotal!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('areaTotal')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.areaTotal }} Ha
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.areaPengembangan!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('areaPengembangan')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.areaPengembangan }} Ha
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.areaKosong!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('areaKosong')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.areaKosong }} Ha
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.skemaPembayaran!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('skemaPembayaran')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.skemaPembayaran }}
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.sewa!=='' || itemModified.biayaSewa!=='' || itemModified.akuisisiPembelian!=='' || itemModified.hargaPembelian!==''"
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('kepemilikanLahan')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.sewa!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('sewa')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.sewa }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.biayaSewa!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('biaya')}} {{$t('sewa')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    Rp. {{ itemModified.biayaSewa }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.akuisisiPembelian!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('akuisisiPembelian')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    Rp. {{ itemModified.akuisisiPembelian }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.hargaPembelian!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('hargaPembelian')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    Rp. {{ itemModified.hargaPembelian }}
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.bangunanKantor !=='' || itemModified.bangunanKomersial !=='' || itemModified.bangunanPabrik !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('bangunanSiapPakai')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.bangunanKantor!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('bangunanKantor')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.bangunanKantor }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.bangunanKomersial!==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('bangunanKomersial')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.bangunanKomersial }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.bangunanPabrik !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('bangunanPabrik')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.bangunanPabrik }}
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.jalanUtama !=='' || itemModified.jalanSekunder !=='' || itemModified.jalanTersier !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('infrastruktur')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jalanUtama !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jalanUtama')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jalanUtama }} meter
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jalanSekunder !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jalanSekunder')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jalanSekunder }} meter
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jalanTersier !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jalanTersier')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jalanTersier }} meter
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.gasAlam !=='' || itemModified.airDomestik !=='' || itemModified.kapasitas !=='' ||
                 itemModified.pengolahanAirLimbah !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('utilitas')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.listrik !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('listrik')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.listrik }} Kva
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.gasAlam !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('gasAlam')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.gasAlam }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.airDomestik !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('airDomestik')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.airDomestik }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.kapasitas !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('kapasitas')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.kapasitas }} /hari
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.pengolahanAirLimbah !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('pengolahanAirLimbah')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.pengolahanAirLimbah }} /{{$t('hari')}}
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.jumlahNomorTelp !=='' || itemModified.jenisInternet !=='' ||
                      itemModified.providerInternet !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('telekomunikasi')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jumlahNomorTelp !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('saluranTelepon')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jumlahNomorTelp }} {{$t('saluranKabel')}}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jenisInternet !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jenisInternet')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jenisInternet }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.providerInternet !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('providerInternet')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.providerInternet }}
                  </v-col>
                </v-row>

              </v-col>


              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.jarakKotaBesar !=='' || itemModified.jarakBandara !=='' || itemModified.jarakPelabuhan !=='' ||
                      itemModified.jarakAdminPerkantoran !==''"
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('jarakDekatTerhadap')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakKotaBesar !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakKotaBesar')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakKotaBesar }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakBandara !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{ $t('jarakBandara') }}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakBandara }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakPelabuhan !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakPelabuhan')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakPelabuhan }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakAdminPerkantoran !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakAdminPerkantoran')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakAdminPerkantoran }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.jarakTerminal !==''  || itemModified.jarakHotel !==''  || itemModified.jarakPemukiman !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('jarakDekatTerhadap')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakTerminal !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakTerminal')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakTerminal }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakHotel !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakHotel')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jarakHotel }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jarakPemukiman !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jarakPemukiman')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5" >
                    {{ itemModified.jarakPemukiman }}
                  </v-col>
                </v-row>

              </v-col>


              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.jumlahTenan !=='' ||  itemModified.blockTenanAvailable !=='' || itemModified.blockTenanReady !=='' "
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('infromasiTenan')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.jumlahTenan !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jumlahTenan')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.jumlahTenan }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.blockTenanAvailable !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('blokTenanTersedia')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.blockTenanAvailable }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.blockTenanReady !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('blokTenanSiap')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.blockTenanReady }}
                  </v-col>
                </v-row>

              </v-col>

              <v-col cols="12" sm="4" md="3" class="elevation-5 rounded-lg mx-1 my-2"
                v-if="itemModified.halalJenisPembatasZona !=='' || itemModified.halalLogistik !=='' || itemModified.halalLabDanLph !==''"
              >
                <v-btn color="green lighten-3" class="mt-n10" x-small rounded>{{$t('kriteriaKIH')}}</v-btn>
                <v-row no-gutters class="small-line-height" v-if="itemModified.halalJenisPembatasZona !=='' ">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('jenisPembatasanZonaHalal')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.halalJenisPembatasZona }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.halalLogistik !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('logistik')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.halalLogistik }}
                  </v-col>
                </v-row>
                <v-row no-gutters class="small-line-height" v-if="itemModified.halalLabDanLph !==''">
                  <v-col cols="11" sm="6" md="6">
                    {{$t('laboratoriumDanLPH')}}
                  </v-col>
                  <v-col cols="1" sm="1" md="1">
                    :
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    {{ itemModified.halalLabDanLph }}
                  </v-col>
                </v-row>

              </v-col>

            </v-row>


          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>

</div>
</template>

<script>
export default {
  name: "KawasanDetailInfo",
  props:{
    itemModified : Object
  }
}
</script>

<style scoped>

</style>