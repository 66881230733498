<template>
  <div >
    <l-map
        :zoom="zoom"
        :max-zoom="maxZoom"
        :center="centered.coordinates"
        ref="map"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
        style="z-index: 0; height: 437px; "
        :options="{scrollWheelZoom: false}"
    >
      <l-tile-layer
          :url="url"
          :attribution="attribution"
      />

      <l-control-layers position="topright"></l-control-layers>
      <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"/>

      <l-control>
        <div class="text-center">
          <v-menu
              v-model="showLayers1"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-x
              max-height="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="indigo"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-lg"
                  icon
                  large
                  outlined
                  elevation="10"
              >
                <v-icon>mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-card width="500px" elevation="16" class="rounded-lg">
              <v-list>
                <v-list-item class="no-gutters" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{itemModified.kode2}}</v-list-item-title>
                    <v-list-item-subtitle>{{$t('dataPrimerDanPendukung')}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="showLayers1 = !showLayers1"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsSpaMain" :key="index"
                  >
                    <v-checkbox
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsSpaExt" :key="index"
                  >
                    <v-checkbox
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>


            </v-card>
          </v-menu>
        </div>

      </l-control>

      <l-control>
        <div class="text-center">
          <v-menu
              v-model="showLayers2"
              :close-on-content-click="false"
              :nudge-width="300"
              offset-x
              max-height="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="success"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  class="rounded-lg"
                  icon
                  large
                  outlined
                  elevation="10"
              >
                <v-icon>mdi-layers</v-icon>
              </v-btn>
            </template>

            <v-card width="500px" elevation="16" class="rounded-lg">
              <v-list>
                <v-list-item class="no-gutters" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{$t('infrastruktur')}} & {{ $t('sektor') }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                        icon
                        @click="showLayers2 = !showLayers2"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFInfrastructureType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedInfraMarker(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedInfraMarker(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFSectorType" :key="index"
                  >
                    <v-checkbox
                        v-if="lang==='id'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                    <v-checkbox
                        v-if="lang==='en'"
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.descriptionEn.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text v-if="false">
                <v-row class="ml-4" no-gutters>
                  <v-col cols="12" md="6" sm="6" xs="12"
                         v-for="(item, index) in computedItemsFKomoditiType" :key="index"
                  >
                    <v-checkbox
                        v-model="item.selected"
                        input-value="true"
                        dense
                        hide-details
                        :label="item.description.trim()"
                        class="caption"
                        @change="valueChangedLuasan(item)"
                    ></v-checkbox>
                  </v-col>
                </v-row>

              </v-card-text>

            </v-card>
          </v-menu>
        </div>

      </l-control>

      <l-marker
          :key="computedCurrentMarker.id"
          :lat-lng="computedCurrentMarker.coordinates"
          ref="marker"
      >

        <l-icon
            :icon-size="computedCurrentMarker.iconSize"
            :icon-anchor="computedCurrentMarker.iconAnchor"
            :icon-url="computedCurrentMarker.iconUrl"
        />

        <l-popup>
          <v-flex class="d-flex" v-if="lang==='id'">
            {{ itemModified.kode2 }}
          </v-flex>
          <v-flex class="d-flex" v-if="lang==='en'">
            {{ itemModified.kode2En }}
          </v-flex>
          <v-flex class="mt-1" v-if="itemModified.district !==undefined">
            <div class="caption font-weight-bold ">
              {{ itemModified.district }}
              ,
              {{ itemModified.city }}
            </div>
          </v-flex>

          <v-flex class="mt-1 caption small-line-height">
            <div  v-if="itemModified.areaTotal >0">
              <span class="grey--text text--darken-1">{{$t('areaTotal')}}:</span> {{ itemModified.areaTotal }} Ha
            </div>
            <div  v-if="itemModified.areaPengembangan >0">
              <span class="grey--text text--darken-1">{{$t('areaPengembangan')}}:</span> {{ itemModified.areaPengembangan }} Ha
            </div>
            <div v-if="itemModified.areaKosong >0">
              <span class="grey--text text--darken-1">{{$t('areaKosong')}}:</span> {{ itemModified.areaKosong }} Ha
            </div>
            <div v-if="itemModified.skemaPembayaran">
              <span class="grey--text text--darken-1">{{$t('skemaPembayaran')}}:</span> {{ itemModified.skemaPembayaran }}
            </div>
            <div v-if="itemModified.hargaPembelian >0">
              <span class="grey--text">{{ $t('hargaPembelian') }}:</span> Rp. {{ formatNumberString(itemModified.hargaPembelian) }}
            </div>
          </v-flex>

        </l-popup>

      </l-marker>

      <l-geo-json
          v-for="(item, index) in computedItemsSpaMainGeojson" :key="index"
          :geojson="item.data"
          :options="options"
          :options-style="styleFunction(item)"
      >
      </l-geo-json>

      <l-marker
          v-for="marker in computedInfraMarkers "
          :key="marker.id"
          :lat-lng="marker.coordinates"
      >
        <l-tooltip class="rounded-lg">
          <div class="font-weight-bold" v-if="lang==='id'">
            {{ lookupFInfrastructure(marker.id).description }}
          </div>
          <div class="font-weight-bold" v-if="lang==='en'">
            {{ lookupFInfrastructure(marker.id).descriptionEn }}
          </div>
          <div>
            {{ lookupFSubArea(lookupFInfrastructure(marker.id).fsubAreaBean).description }}
            -
            {{ lookupFArea(lookupFSubArea(lookupFInfrastructure(marker.id).fsubAreaBean).fareaBean).description }}
          </div>
        </l-tooltip>
        <l-icon
          :icon-size="marker.iconSize"
          :icon-url="marker.iconUrl"
        />
        <l-popup>
          <v-flex class="d-flex">
            {{ lookupFInfrastructure(marker.id).description }}
          </v-flex>
          <v-flex class="mt-1" v-if="lookupFInfrastructure(marker.id).district !==undefined">
            <div class="caption font-weight-light">
              {{ lookupFInfrastructure(marker.id).district }}
            </div>
          </v-flex>
        </l-popup>
      </l-marker>

      <l-control-polyline-measure :options="{ showUnitControl: true }" position="topleft"/>

    </l-map>
  </div>
</template>

<script>
import { Icon } from "leaflet";
import { LMap, LTileLayer,
  LControl,
  LControlLayers,
  LMarker,
  LPopup, LTooltip,
  LIcon,
  LGeoJson } from "vue2-leaflet";
import 'leaflet/dist/leaflet.css';
import FileService from "@/services/apiservices/file-service";
import FInfrastructureService from "@/services/apiservices/f-infrastructure-service";

import LControlPolylineMeasure from 'vue2-leaflet-polyline-measure';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: "KawasanDetailmap",
  components: {
    LMap,
    LTileLayer,
    LControl,
    LControlLayers,
    LMarker,
    LIcon,
    LPopup, LTooltip,
    LGeoJson,
    LControlPolylineMeasure
  },
  props:{
    itemModified: Object,
    itemsFile:[]
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
    itemsFile:{
      handler: function (value, oldValue){
        // console.log(oldValue.length)
        console.log(value)
        if (oldValue.length==0){
          // console.log(value)
          value.forEach(item => {
            if (item.selected){
              this.valueChangedLuasan(item)
            }
          })
        }
      }

    }

  },
  data() {
    return {
      zoom: 14,
      maxZoom: 18,
      // center_TidakDipakai: latLng(47.41322, -1.219482),//Kebalik bos
      centered:{id: 1, coordinates: [-7.250445, 112.768845]},
      // centered:{id: 1, coordinates: [47.313220, -1.319482]},

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: true,
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        },
        {
          name: 'OpenTopoMap',
          visible: false,
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          attribution:
              'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
          attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }
      ],


      showLayers1: false,
      showLayers2: false,

      geojson: null,
      itemSpaMainGeojson: [],

      finfratructures:[],
      finfratructureTypesSelected:[],

    };
  },
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    computedCurrentMarker(){
      let marker = {id: 1, coordinates: [this.itemModified.lat, this.itemModified.lon]}

      const sectorType = this.lookupFSectorType(this.itemModified.fsectorTypeBean)
      const sectorTypeMarker1 = sectorType.markerImage1
      marker = {id: this.itemModified.id, coordinates: [this.itemModified.lat, this.itemModified.lon],
        iconUrl: this.lookupImageMarkerUrl(sectorTypeMarker1),
        iconSize: [45, 65],
      }

      /**
       * Melakukan Update secara Programming ke Center
       */
      if (this.itemModified.lat !=0 && this.itemModified.lon !=0) {
        this.$nextTick(() => {
          this.centered = marker
          this.$refs.marker.mapObject.openPopup()
        })
      }

      return marker
    },

    computedInfraMarkers(){
      let markers = []

      if (this.finfratructures !==undefined){
        this.finfratructures.forEach( item =>{

          // console.log(JSON.stringify(item))

          if (item.lat !==0 && item.lon !==0){

            const infrastructureType = this.lookupFInfrastructureType(item.finfrastructureTypeBean)
            const infrastructureTypeMarker1 = infrastructureType.markerImage1

            let marker = {id: item.id, coordinates: [item.lat, item.lon],
              iconUrl: this.lookupImageMarkerUrl(infrastructureTypeMarker1),
              iconSize: [23, 35],
            }

            markers.push(marker)
          }
        })
      }

      return markers
    },

    computedItemsSpaMain(){
      return this.itemsFile.filter(item => item.potensiType===4 && item.fileType=="SPA")
    },
    computedItemsSpaMainGeojson(){
      return this.itemSpaMainGeojson
    },

    computedItemsSpaExt(){
      return this.itemsFile.filter(item => item.potensiType===5 && item.fileType=="SPA")
    },

    computedItemsFSectorType(){
      return this.$store.state.potensi.itemsFSectorType.filter(x => x.statusActive==true)
    },
    computedItemsFKomoditiType(){
      return this.$store.state.potensi.itemsFKomoditiType.filter(x => x.statusActive==true)
    },

    computedItemsFInfrastructureType(){
      return this.$store.state.potensi.itemsFInfrastructureType.filter(x => x.statusActive==true)
    },
    computedItemsFSubArea(){
      return this.$store.state.potensi.itemsFSubArea
    },
    computedItemsFArea(){
      return this.$store.state.potensi.itemsFArea
    },

    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },

    styleFunction() {
      let lineColor = "blue";
      let fillColor = "red";

      // console.log(JSON.stringify(this))

      return value => {

        if (value){
          lineColor = value.lineColor
          fillColor = value.fillColor
        }

        return {
          weight: 1,
          color: lineColor,
          opacity: 0.4,
          fillColor: fillColor,
          fillOpacity: 0.3
        };
      };

    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
            "<div>code:" +
            feature.properties.code +
            "</div><div>nom: " +
            feature.properties.name +
            "</div>",
            { permanent: false, sticky: true }
        );
      };
    }

  },
  methods: {

    zoomUpdated (zoom) {
      this.zoom = zoom;
      // console.log(this.markers)
    },
    centerUpdated (center) {
      this.center = center;
    },
    changeSelection(value){
      value.toString()

      const tipe = this.lookupFInfrastructureType(this.itemModified.finfrastructureTypeBean)
      const theIconUrl = this.lookupImageMarkerUrl(tipe.markerImage1)
      const newMarker =
          {
            id: 1,
            iconUrl: theIconUrl,
            coordinates: [value.lat, value.lon],
            iconSize: [45, 65],
            // iconAnchor: [16, 37]
          }

      this.$nextTick(() => {
        this.itemModified = Object.assign({}, value)
        this.currentMarker = newMarker
        this.zoom = 10
        this.$refs.marker.mapObject.openPopup()

      })

    },
    lookupFPotensi (fpotensiBean) {
      const items = this.computedPotensies.filter(x => x.id==fpotensiBean)
      if (items.length>0){
        return items[0]
      }else {
        return Object
      }
    },

    lookupFSectorType (fsectorTypeBean) {
      const str = this.computedItemsFSectorType.filter(x => x.id===fsectorTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFInfrastructure (finfrastructureBean) {
      const str = this.finfratructures.filter(x => x.id===finfrastructureBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFInfrastructureType (finfrastructureTypeBean) {
      const str = this.computedItemsFInfrastructureType.filter(x => x.id===finfrastructureTypeBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },
    lookupFSubArea (fsubAreaBean) {
      const str = this.computedItemsFSubArea.filter(x => x.id===fsubAreaBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },
    lookupFArea (fareaBean) {
      const str = this.computedItemsFArea.filter(x => x.id===fareaBean)
      if (str.length>0){
        return str[0]
      }else {
        return ''
      }
    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },
    lookupImageMarkerUrl(markerImage){
      if (markerImage===undefined || markerImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_ori(markerImage)
      }
    },

    async valueChangedLuasan(value){
      // console.log(JSON.stringify(value))
      // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
      // this.geojson = await response.json();

      const response = await fetch(FileService.file_url(value.fileName));

      // console.log(response)

      if (value.selected ==true){
        const geojsonResponse = await response.json();
        this.itemSpaMainGeojson.push( {id: value.id, data: geojsonResponse, lineColor: value.remark1, fillColor: value.remark2} )

      }else {
        // this.geojson = {}

        const index = this.itemSpaMainGeojson.findIndex(item => item.id === value.id)
        this.itemSpaMainGeojson.splice(index, 1)
      }
    },

    valueChangedInfraMarker(value){
      // console.log(JSON.stringify(value))

      if (value.selected ===true){
        this.finfratructureTypesSelected.push(value.id)
      }else {
        const index = this.finfratructureTypesSelected.findIndex(itemId => itemId === value.id)
        this.finfratructureTypesSelected.splice(index, 1)
      }

      if (this.finfratructureTypesSelected.length === 0) {
        this.finfratructures = []
        return
      }

      // console.log(this.itemModified.city)

      FInfrastructureService.getAllFInfrastructureByTypesAndFareaBeanPublic(this.finfratructureTypesSelected, this.itemModified.fareaBean).then(
          response =>{
            // console.log(JSON.stringify(response))
            this.finfratructures = response.data

          }
      )

      // FInfrastructureService.getAllFInfrastructureByCityAndInfrastructurePublic(this.itemModified.city, this.finfratructureTypesSelected).then(
      //     response =>{
      //       // console.log(JSON.stringify(response))
      //       this.finfratructures = response.data
      //
      //     }
      // )

    },
    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000 >1) {
        valueStr = `${parseFloat(value / 1000000000).toLocaleString()} Miliar`
      }else if(value/1000000>1){
        valueStr = `${parseFloat(value / 1000000).toLocaleString()} Juta`
      }else if(value/1000>1){
        valueStr = `${parseFloat(value / 1000).toLocaleString()} Ribu`
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },

    openGoogleMap(item) {

      const urlSuffix = `${item.lat},${item.lon}&z=15`
      window.open(
          "https://maps.google.com/maps?q=" + urlSuffix,
          "_blank"
      );
    },

  },

  mounted() {
    // console.log(JSON.stringify(this.itemsFile))

  },


  async created () {
    // const response = await fetch('https://rawgit.com/gregoiredavid/france-geojson/master/regions/pays-de-la-loire/communes-pays-de-la-loire.geojson');
    // this.geojson = await response.json();

    // console.log(JSON.stringify(this.itemsFile))

  }


};
</script>

<style scoped>
  .small-line-height{
    line-height: 1em;
  }
</style>
