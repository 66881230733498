import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FInfrastructureTypeService {
    getApiUrl() {
        return API_URL
    }
    getAllFInfrastructureTypePublic(){
        return axios.get(API_URL + `getAllFInfrastructureTypePublic`, { headers: authHeader() });
    }
    getAllFInfrastructureType(){
        return axios.get(API_URL + `getAllFInfrastructureType`, { headers: authHeader() });
    }
    getAllFInfrastructureTypeByOrgLevel(){
        return axios.get(API_URL + `getAllFInfrastructureTypeByOrgLevel`, { headers: authHeader() });
    }

    getAllFInfrastructureTypeContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFInfrastructureType`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFInfrastructureTypeContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFInfrastructureTypeById(id){
        return axios.get(API_URL + `getFInfrastructureTypeById/${id}`, { headers: authHeader() });
    }
    updateFInfrastructureType(item){
        return axios.put(API_URL + `updateFInfrastructureType/${item.id}`, item, {headers: authHeader()})
    }
    createFInfrastructureType(item){
        return axios.post(API_URL + `createFInfrastructureType`, item, {headers: authHeader()})
    }
    deleteFInfrastructureType(id){
        return axios.delete(API_URL + `deleteFInfrastructureType/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFInfrastructureType(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFInfrastructureType`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFInfrastructureType`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FInfrastructureTypeService()