<template>
  <div>
    <KawasanDetail></KawasanDetail>
  </div>
</template>

<script>
import KawasanDetail from "@/components/public_kawasan_detail/KawasanDetail";
export default {
  name: "KawasanDetailView",
  components: {
    KawasanDetail,
  },
};
</script>

<style scoped>
</style>