<template>
  <v-card flat>
    <v-toolbar
        color="white"
        dark
        prominent
        height="400px"
        elevation="0"
    >
      <v-img
          :src="lookupAvatarUrl(itemModified)"
          :lazy-src="lookupAvatarUrlLazy(itemModified)"
          height="400px"
          aspect-ratio="1"
      >
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>

      </v-img>
    </v-toolbar>

    <v-flex
        class="mx-auto d-flex"
        style="margin-top: -32px;"
    >
      <v-row>
        <v-col cols="12" sm="8" md="8">
          <v-card
              class="mx-auto ml-10"
          >
            <v-card-title v-if="lang=='id'">{{itemModified.kode2}}</v-card-title>
            <v-card-title v-if="lang=='en'">{{itemModified.kode2En}}</v-card-title>
            <v-card-text v-if="true" class="body-1">

              <v-textarea
                  v-if="lang=='id'"
                  v-model="itemModified.description"
                  flat
                  readonly
                  auto-grow
                  dense
                  solo
              ></v-textarea>
              <v-textarea
                  v-if="lang=='en'"
                  v-model="itemModified.descriptionEn"
                  flat
                  readonly
                  auto-grow
                  dense
                  solo
              ></v-textarea>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-flex>

    <v-card
        height="500px"
        class="ml-16 mr-16 mt-6"
        elevation="2"
    >
      <v-card-title>{{$t('peta')}}</v-card-title>

        <v-card class="elevation-5 rounded">
          <PotensiDetailMap
              :itemModified="itemModified"
              :itemsFile="itemsFile"
          ></PotensiDetailMap>
        </v-card>

    </v-card>

    <KawasanDetailInfo
        :itemModified="itemModified"
    ></KawasanDetailInfo>

    <v-card
        class="ml-16 mr-16 mt-6"
        elevation="2"
        v-if="itemsInfomemoFiltered.length>0"
    >
      <v-tabs
          color="deep-purple accent-4"
          left
      >
        <v-tab>{{$t('informasi')}} & Infomemo</v-tab>

        <v-tab-item
            v-for="n in 3"
            :key="n"
        >
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(item, i) in itemsInfomemoFiltered"
                :key="i"
                cols="12"
                sm="6"
                md="6"
                lg="6"
              >
                <v-hover>
                  <template v-slot:default="{ hover }">
                      <v-img
                      :src="lookupImageUrl(item)"
                      :lazy-src="lookupImageUrlLazy(item)"
                      class="elevation-10 rounded-bl-lg"
                      @click="showImage(item)"
                      :class="{ 'hover-image': hover }"
                      ></v-img>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-card
        class="ml-16 mr-16 mt-6"
        elevation="2"
        v-if="itemsGalleryFiltered.length>0"
    >
      <v-tabs
          color="deep-purple accent-4"
          left
      >
        <v-tab>{{$t('galeri')}}</v-tab>

        <v-tab-item
            v-for="n in 3"
            :key="n"
        >
          <v-container fluid>
            <v-row>
              <v-col
                v-for="(item, i) in itemsGalleryFiltered"
                :key="i"
                cols="12"
                sm="4"
                md="4"
                lg="3"
              >
                <v-hover>
                  <template v-slot:default="{ hover }">
                      <v-img
                        :src="lookupImageUrl(item)"
                        :lazy-src="lookupImageUrlLazy(item)"
                        class="elevation-10 rounded-lg"
                        @click="showImage(item)"
                        :class="{ 'hover-image': hover }"
                      ></v-img>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <v-card
        class="ml-16 mr-16 mt-6"
        elevation="2"
        v-if="itemsVideoFiltered.length>0"
    >
      <v-tabs
          color="deep-purple accent-4"
          left
      >
        <v-tab>Video</v-tab>

        <v-tab-item
            v-for="n in 3"
            :key="n"
        >
          <v-container fluid>
            <v-row>

              <v-col
                  v-for="(item, i) in itemsVideoFiltered"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="6"
                  lg="4"

              >
                <v-hover>
                  <template v-slot:default="{ hover }">
                <Media
                    :kind="'video'"
                    :isMuted="(false)"
                    :src="urlMp4File(item)"
                    :controls="true"
                    :autoplay="false"
                    :ref="'fish'"
                    :style="{width: '450px'}"
                    @pause="handle()"
                    class="rounded-lg elevation-10"
                    :class="{ 'hover-image': hover }"
                >
                </Media>
                  </template>
                </v-hover>

              </v-col>


            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <div class="mt-4"></div>
    <v-row v-if="false">
      <v-col cols="12" sm="6" md="6">
        <v-card
            class="ml-6 mt-6 mb-4"
            elevation="2"
        >
          <v-card-title>{{$t('kontak')}}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="ml-1 no-gutters">
              <v-col cols="12" md="3" sm="9">{{$t('kontak')}}</v-col>
              <v-col cols="12" md="9" sm="9">{{itemModified.namaKontak1}}</v-col>
            </v-row>
            <v-row class="ml-1 no-gutters">
              <v-col cols="12" md="3" sm="9">Alamat</v-col>
              <v-col cols="12" md="9" sm="9">{{itemModified.alamatKontak1}}</v-col>
            </v-row>
            <v-row class="ml-1 no-gutters">

              <v-col cols="12" md="6" sm="6">
                <span>
                  <v-icon x-small>mdi-phone-classic</v-icon>
                  {{itemModified.phoneKontak1}}
                </span>
                <span class="ml-4">
                  <v-icon x-small>mdi-email</v-icon>
                  {{itemModified.emailKontak1}}
                </span>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <v-card
            class="mr-6 mt-6 mb-4"
            elevation="2"
        >
          <v-card-title>{{$t('unduhanDokumen')}}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="ml-4 mr-4">
              <v-text-field outlined dense :label="$t('namaPerusahaan')"></v-text-field>
            </div>
            <div class="ml-4 mr-4">
              <v-text-field outlined dense label="Email"></v-text-field>
            </div>
            <div class="ml-4 mr-4">
              <v-text-field outlined dense :label="$t('noTelepon')"></v-text-field>
            </div>
            <div class="ml-4 mr-4">
              <v-text-field outlined dense :label="$t('asalNegara')"></v-text-field>
            </div>
            <div class="ml-4 mr-4">
              <v-autocomplete
                  v-model="values"
                  :items="itemsTujuan"
                  outlined
                  dense
                  chips
                  small-chips
                  :label="$t('tujuan')"
                  class="ml-1 mr-1"
              ></v-autocomplete>
            </div>
          </v-card-text>

          <v-card-actions class="mr-4">
            <v-spacer></v-spacer>
            <v-btn color="primary">{{$t('kirim')}}</v-btn>

          </v-card-actions>
        </v-card>
      </v-col>

    </v-row>

    <AppFooter></AppFooter>

    <FPotensiDetilShowImageDialog
        ref="refShowImage"
    ></FPotensiDetilShowImageDialog>

  </v-card>
</template>


<script>

import AppFooter from "@/components/PublicFooter";
import FileService from "@/services/apiservices/file-service";
import FPotensiService from "@/services/apiservices/f-potensi-service";
import FPotensi from "@/models/f-potensi";
import PotensiDetailMap from "./KawasanDetailMap"
import FPotensiFileService from "@/services/apiservices/f-potensi-file-service";

import Media from '@dongido/vue-viaudio'
import FPotensiDetilShowImageDialog from "@/components/utils/ShowImageDialog";
import FSectorTypeService from "@/services/apiservices/f-sector-type-service";
import FKomoditiTypeService from "@/services/apiservices/f-komoditi-type-service";
import FInfrastructureTypeService from "@/services/apiservices/f-infrastructure-type-service";
import KawasanDetailInfo from "@/components/public_kawasan_detail/KawasanDetailInfo";
import FSubAreaService from "@/services/apiservices/f-sub-area-service";

export default {
  name: "KawasanDetail",
  components: {
    KawasanDetailInfo,
    FPotensiDetilShowImageDialog,
    PotensiDetailMap,
    AppFooter,
    Media
  },
  data: () =>({
    itemsTypePotensi:[
    ],
    itemModified: new FPotensi(),
    itemsFile: [],

    itemsFDivision:[],

    itemsTujuan:[
      'Pendidikan/Penelitian', 'Informasi Investasi', 'Referensi Investasi', 'Informasi Umum'
    ],

  }),
  computed:{
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    itemsInfomemoFiltered(){
      return this.itemsFile
          .filter(item => item.potensiType===1 && (item.fileType==="IMAGE" || item.fileType==="MP4"))
          .sort(( a, b) => a.fileType > b.fileType)
    },
    itemsGalleryFiltered(){
      return this.itemsFile
          .filter(item => item.potensiType===2 && (item.fileType==="IMAGE" || item.fileType==="MP4"))
          .sort(( a, b) => a.fileType > b.fileType)
    },
    itemsVideoFiltered(){
      return this.itemsFile
          .filter(item => item.potensiType===3 && (item.fileType==="MP4"))
          .sort(( a, b) => a.fileType > b.fileType)
    }

  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },
  },
  methods: {
    fetchParent(){

      FSectorTypeService.getAllFSectorTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFSectorType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )
      FKomoditiTypeService.getAllFKomoditiTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFKomoditiType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )

      FInfrastructureTypeService.getAllFInfrastructureTypePublic().then(
          response => {
            this.$store.dispatch('potensi/updateItemsFInfrastructureType', response.data)
          },
          error => {
            console.log(error.response)
          }
      )


    },

    lookupAvatarUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_high(item.avatarImage)
      }
    },
    lookupAvatarUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

    lookupImageUrl(item){
      // console.log(item.fileName)
      if (item.fileName===undefined){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_high(item.fileName)
      }
    },
    lookupImageUrlLazy(item){
      return FileService.image_url_verylow(item.fileName)
    },

    formatNumberString(value){
      let valueStr = ""
      if (value/1000000000000 >1) {
        if(this.lang==='id'){
          valueStr = `${parseFloat(value / 1000000000000).toFixed(2) } Triliun`
        }
        if(this.lang==='en'){
          valueStr = `${parseFloat(value / 1000000000000).toFixed(2) } Trilion`
        }
      }else if(value/1000000000>1){
        if(this.lang==='id'){
          valueStr = `${parseFloat(value / 1000000000).toFixed(2) } Miliar`
        }
        if(this.lang==='en'){
          valueStr = `${parseFloat(value / 1000000000).toFixed(2) } Billion`
        }
      }else if(value/1000000>1){
        if(this.lang==='id'){
          valueStr = `${parseFloat(value / 1000000).toFixed(2) } Juta`
        }
        if(this.lang==='en'){
          valueStr = `${parseFloat(value / 1000000).toFixed(2) } Million`
        }
      }else if(value/1000>1){
        if(this.lang==='id'){
          valueStr = `${parseFloat(value / 1000).toFixed(2) } Ribu`
        }
        if(this.lang==='en'){
          valueStr = `${parseFloat(value / 1000).toFixed(2) } Thousand`
        }
      }else{
        valueStr = value.toString()
      }
      return valueStr
    },

    retrieveFiles(){
      FSubAreaService.getFSubAreaById(this.itemModified.fsubAreaBean).then(
          response =>{
            this.itemModified.fareaBean = response.data.fareaBean
          }
      )
      FPotensiFileService.getAllFPotensiFileByParent(this.itemModified.id).then(
          response=>{
            // console.log(JSON.stringify(response.data))
            this.itemsFile = response.data
          },
          error=>{
            console.log(error.response)
          }
      )
    },


    urlMp4File (item) {
      return FileService.fileStreamMp4File(item.fileName)
    },
    handle() {
      console.log('Video paused!, playing in 2 sec...')

      setTimeout( () => {
        this.$refs.fish.play()
      }, 2000)
    },

    showImage(value){
      // console.log(JSON.stringify(value))
      this.$refs.refShowImage.showDialog(value)
    }

  },

  mounted() {
    const id = this.$route.params.id
    // console.log(`Id nya adalah: ${id}`)

    this.fetchParent()

    FPotensiService.getFPotensiById(id).then(
        response =>{
          this.itemDefault = Object.assign({}, response.data)
          this.itemModified = response.data

          this.retrieveFiles()


          // console.log(this.itemModified.city)
        },
        error =>{
          console.log(error)
        }
    )




  }

}
</script>

<style scoped>

.hover-image{
  opacity: 0.6;
  cursor:pointer;

}

.small-line-height{
  line-height: 1.2em;
}
</style>