import { render, staticRenderFns } from "./KawasanDetailInfo.vue?vue&type=template&id=51f42682&scoped=true"
import script from "./KawasanDetailInfo.vue?vue&type=script&lang=js"
export * from "./KawasanDetailInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f42682",
  null
  
)

export default component.exports